
<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar :title="blockName+'商城'" left-arrow @click-left="$router.back()" />
    </van-sticky>
    <van-row>
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="page.filterData.typeId"
            :options="eshopClass"
            @change="search"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="page.filterData.name"
          @input="search"
          placeholder="请输入关键字"
        />
      </van-col>
    </van-row>
    <van-divider>{{blockName}}商品</van-divider>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <div class="van-clearfix">
          <div
            class="layout_wrapper"
            style="
              float: left;
              width: 50%;
              text-align: center;
              background-color: #f8f8f8;
              overflow: hidden;
            "
            @click="toDetail(item.id.toString())"
            v-for="item in articles"
            :key="item.id.toString()"
          >
            <div class="product-layout__item">
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 180px"
                :src="item.imageUrl"
              />
              <div style="text-align: left;padding-left: 10px;  height: 42px">
                  {{ item.name }}
              </div>
               <div style="text-align: left;padding-left: 10px;    margin-bottom: 10px;">
                 <span>{{blockName}}:</span>
    <span  style="color: #9d1d22;
    font-size: 18px;
    font-weight: bold;">
                  {{ item.score }}
    </span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getEshopProductList } from "@/api/shop";
import { getEshopProductClass } from "@/api/shop";

export default {
  name: "eshop",
  data() {
    return {
      scroll: 0, //位置变量
      refreshing: false,
      blockName:"",
      name: "",
      dropvalue: 0, //样式
      articles: [], //数组储存接收来的对象

      list: [],
      upLoading: false,
      finished: false,

      //从接口调用
      eshopClass: [{ text: "全部", value: 0 }],
      page: {
        sorterType: 0,
        current: 1,
        fromType:1,
        pageSize: 10,
        filterData: {
          name: "",
          typeId: 0,
          blockId:0,
        },
      },
    };
  },
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },

  methods: {
    onRefresh() {
      this.search();
      this.refreshing = false;
    },
    search() {
      this.finished = false;
      this.page.current = 1;
      this.articles = [];
      this.onLoad();
    },
    // onSearch(val){
    //     Toast(val);
    // },
    async initData() {

      this.page.filterData.blockId=this.$route.query.blockId;
       if(this.page.filterData.blockId==0){
      this.blockName='积分';
     }
     else if(this.page.filterData.blockId==1){
      this.blockName='能量值';
     }



      let eshopProductList = await getEshopProductClass( this.page.filterData.blockId);

      eshopProductList.data.data.map((x) =>
        this.eshopClass.push({ text: x.typeName, value: x.id })
      );
    },

    toDetail(id) {

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.$router.push({
        path: "/eshop/detail",
        query: { para: id},
      });
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getEshopProductList({ ...this.page });
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    //width: 100%;
    height: 180px;
    max-height: 180px;
  }
}

.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.product-layout__item {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  margin: 2px;
}
</style>
